
import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import DepositBottomSheet from "@/components/deposit/DepositBottomSheet.vue";
import { API_ENDPOINT_SYMBOL, IS_MOBILE_SYMBOL } from "@/constants";
import DepositDialog from "@/components/deposit/DepositDialog.vue";

@Component({
  components: {
    DepositBottomSheet,
    DepositDialog,
  },
})
export default class Deposit extends Vue {
  @Inject(API_ENDPOINT_SYMBOL) apiEndpoint!: string;
  @Inject(IS_MOBILE_SYMBOL) isMobile!: boolean;
  private show = false;

  onDepositCreated({ _id }) {
    window.open(this.apiEndpoint + `deposits/redirect/${_id}`, "_blank");
    this.$notify({
      type: "info",
      text: `Вы будете перенаправлены на страницу оплаты, если этого не происходит, нажмите кнопку <a href="${
        this.apiEndpoint + `deposits/redirect/${_id}`
      }" target="_blank">перейти</a>.`,
      data: {
        actions: [
          {
            label: `Перейти`,
            handler: () =>
              window.open(
                this.apiEndpoint + `deposits/redirect/${_id}`,
                "_blank"
              ),
          },
        ],
      },
    });
    this.show = false;
  }

  @Watch(`$route.hash`, { immediate: true })
  watchRouteHash(value) {
    if (value === "#deposit") {
      this.show = true;
    } else if (this.show) {
      this.show = false;
    }
  }

  @Watch(`show`)
  watchShow(value) {
    if (!value) {
      this.$router.replace(this.$route.path);
    }
  }
}
