
import { Component, Prop, Vue } from "vue-property-decorator";
import AccountBox from "@/components/accounts/AccountBox.vue";
import BaseSlideList from "@/components/base/BaseSlideList.vue";
import { AccountBoxEntity } from "@/entities/account-box.entity";

@Component({
  components: {
    AccountBox,
    BaseSlideList,
  },
})
export default class AccountBoxSlideList extends Vue {
  @Prop() value!: AccountBoxEntity[];
}
