
import {Component, Prop, Vue} from "vue-property-decorator";
import BaseSlideList from "@/components/base/BaseSlideList.vue";
import {ISnowBox} from "@/interfaces/snow-box.interface";
import SnowBox from "@/components/boxes/SnowBox.vue";

@Component({
  components: {
    SnowBox,
    BaseSlideList,
  },
})
export default class SnowBoxSlideList extends Vue {
  @Prop() value!: ISnowBox[];
  @Prop({default: 3}) loadingCount!: number;
}
