
import { Component, Prop } from "vue-property-decorator";
import { VBtn } from "vuetify/lib";

@Component({})
export default class GradientBtn extends VBtn {
  @Prop({}) borderless: any;

  genContent() {
    return this.$createElement(
      "div",
      {
        staticClass:
          "v-btn__contents" +
          (this.borderless !== undefined ? " borderless" : ""),
      },
      [this.$slots.default]
    );
  }
}
