
import {
  Component,
  Inject,
  InjectReactive,
  Vue,
  Watch,
} from "vue-property-decorator";
import { IS_MOBILE_SYMBOL, TDrawerState, USER_SYMBOL } from "@/constants";
import { AuthUserEntity } from "@/entities/user.entity";
import Chat from "@/components/app/AppChat.vue";
import ThemeToggle from "@/components/base/ThemeToggle.vue";
import AppVolumeControl from "@/components/app/AppVolumeControl.vue";

import star from "../../assets/drawer-icons/star.svg";
import flash from "../../assets/drawer-icons/flash.svg";
import home from "../../assets/drawer-icons/home.svg";
import market from "../../assets/drawer-icons/market.svg";
import inventory from "../../assets/drawer-icons/inventory.svg";
import support from "../../assets/drawer-icons/support.svg";
import group from "../../assets/drawer-icons/group.svg";
import wallet from "../../assets/drawer-icons/wallet.svg";
import activeBg from "../../assets/drawer-icons/active-bg.svg";

import caseOn from "../../assets/drawer-icons/old/cases_on.png";
import caseOff from "../../assets/drawer-icons/old/cases_off.png";
import guncasesOn from "../../assets/drawer-icons/old/guncases_on.png";
import guncasesOff from "../../assets/drawer-icons/old/guncases_off.png";
import accountsOn from "../../assets/drawer-icons/old/accounts_on.png";
import accountsOff from "../../assets/drawer-icons/old/accounts_off.png";
import transactionsOn from "../../assets/drawer-icons/old/transactions_on.png";
import transactionsOff from "../../assets/drawer-icons/old/transactions_off.png";
import inventoryOn from "../../assets/drawer-icons/old/inventory_on.png";
import inventoryOff from "../../assets/drawer-icons/old/inventory_off.png";
import shopskinsOn from "../../assets/drawer-icons/old/shopskins_on.png";
import shopskinsOff from "../../assets/drawer-icons/old/shopskins_off.png";
import swordsOn from "../../assets/drawer-icons/old/swords_on.png";
import swordsOff from "../../assets/drawer-icons/old/swords_off.png";
import bonusOff from "../../assets/drawer-icons/old/bonus_off.png";
import bonusOn from "../../assets/drawer-icons/old/bonus_on.png";
import supportOn from "../../assets/drawer-icons/old/support_on.png";
import supportOff from "../../assets/drawer-icons/old/support_off.png";
import upgradeOn from "../../assets/drawer-icons/old/upgrade_on.png";
import upgradeOff from "../../assets/drawer-icons/old/upgrade_off.png";
import usercaseOn from "../../assets/drawer-icons/old/usercase_on.png";
import usercaseOff from "../../assets/drawer-icons/old/usercase_off.png";

class DrawerItem {
  __typename = "DrawerItem" as const;

  constructor(
    public title: string,
    public subtitle: string,
    public link: string,
    public icon: string,
    public iconActive?: string
  ) {}
}

class DrawerDivider {
  __typename: "DrawerDivider";

  constructor() {
    this.__typename = "DrawerDivider";
  }
}

@Component({
  components: {
    Chat,
    AppVolumeControl,
    ThemeToggle,
  },
})
export default class Drawer extends Vue {
  @InjectReactive(USER_SYMBOL) user!: AuthUserEntity | null;
  @Inject(IS_MOBILE_SYMBOL) isMobile!: boolean;
  private show = true;
  private mini = false;
  private chatWidth = 327;
  private hideChat = false;
  private drawerWidth = 327;
  private drawerMiniWidth = 82;
  private activeBg = Object.freeze(activeBg);
  private items = Object.freeze([
    new DrawerDivider(),
    new DrawerItem(
      "Open case",
      "Открытие кейсов со 100% окупаемостью",
      "/",
      caseOff,
      caseOn
    ),
    new DrawerItem(
      "Gun case",
      "Открытие кейсов без 100% окупаемости",
      "/gun-cases",
      guncasesOff,
      guncasesOn
    ),
    new DrawerItem(
      "Кейс конструктор",
      "Создай свой уникальный кейс",
      "/usercase",
      usercaseOff,
      usercaseOn
    ),
    new DrawerItem(
      "Апгрейд",
      "Приумножь свой инвентарь",
      "/upgrade/",
      upgradeOff,
      upgradeOn
    ),
    new DrawerItem(
      "Сражения",
      "Сражайся до победного",
      "/battle",
      swordsOff,
      swordsOn
    ),
    new DrawerItem(
      "Аккаунты",
      "Получи дорогие скины по доступной цене",
      "/accounts",
      accountsOff,
      accountsOn
    ),
    new DrawerDivider(),
    new DrawerItem(
      "Инвентарь",
      "Ваши предметы/аккаунты",
      "/profile#inventory",
      inventoryOff,
      inventoryOn
    ),
    new DrawerItem(
      "Транзакции",
      "История пополнений/выводов",
      "/profile#transactions",
      transactionsOff,
      transactionsOn
    ),
    new DrawerDivider(),
    new DrawerItem(
      "Магазин",
      "Поиск предметов",
      "/shopskins",
      shopskinsOff,
      shopskinsOn
    ),
    new DrawerItem("Бонусы", "Получайте бонусы", "/bonuses", bonusOff, bonusOn),
    new DrawerItem(
      "Поддержка",
      "Ответим на любые вопросы",
      "/support",
      supportOff,
      supportOn
    ),
  ]);
  private scrollOptions = Object.freeze({
    rail: {
      background: "#01a99a",
      opacity: 0,
      size: "6px",
      specifyBorderRadius: false,
      gutterOfEnds: null,
      gutterOfSide: "2px",
      border: 0,
      keepShow: false,
    },
    bar: {
      showDelay: 500,
      onlyShowBarOnScroll: true,
      keepShow: false,
      background: "#ffffff",
      opacity: 0.2,
      hoverStyle: false,
      specifyBorderRadius: false,
      minSize: 0,
      size: "4px",
      disable: false,
    },
    scrollPanel: {
      scrollingX: false,
      speed: 300,
    },
  });

  get width() {
    const drawerWidth = this.mini ? this.drawerMiniWidth : this.drawerWidth;
    const chatWidth = this.hideChat || !this.chat ? 0 : this.chatWidth;

    return drawerWidth + chatWidth;
  }

  // Регулирует, должен ли быть чат
  get chat() {
    return this.$store.state.theme === "old" && !this.isMobile;
  }

  @Watch("chat")
  chatWatcher(showChat) {
    this.hideChat = !showChat;
    this.mini = showChat ? !this.hideChat : this.mini;
  }

  get value() {
    return this.$store.state.drawerState;
  }

  set value(value) {
    this.$store.commit("setDrawerState", value);
  }

  @Watch("value")
  watchValue(value) {
    if (value === "show") {
      this.show = true;
    } else if (value === "mini") {
      this.show = true;
      this.mini = true;
    } else if (value === "hide") {
      this.show = false;
    }
  }

  @Watch("show")
  @Watch("mini")
  updateValue() {
    let newValue: TDrawerState = "show";

    if (!this.show) {
      newValue = "hide";
    } else if (this.mini) {
      newValue = "mini";
    }

    this.value = newValue;
  }

  created() {
    this.show = !this.isMobile;
    this.mini = this.chat ? true : !this.isMobile;
  }

  toggleMode() {
    if (this.chat) {
      this.hideChat = !this.hideChat;
      this.mini = !this.hideChat;
    } else {
      this.mini = !this.mini;
    }
  }
}
