
import { Component, Vue } from "vue-property-decorator";
import gql from "graphql-tag";

@Component({
  apollo: {
    count: {
      update(data) {
        return data.getUserOnlineCount ? data.getUserOnlineCount || 1 : null;
      },
      query: gql`
        query {
          getUserOnlineCount
        }
      `,
      subscribeToMore: {
        updateQuery: (previousResult, { subscriptionData }) => {
          const key = Object.keys(previousResult)[0];

          return {
            [key]: subscriptionData.data.subscribeUserOnlineCountUpdated,
          };
        },
        document: gql`
          subscription {
            subscribeUserOnlineCountUpdated
          }
        `,
      },
    },
  },
})
export default class UserOnlineCount extends Vue {
  private count: number | null = null;
}
