
import { Component, Prop, Vue } from "vue-property-decorator";
import Box from "@/components/boxes/Box.vue";
import Grid from "@/components/base/Grid.vue";

@Component({
  components: {
    Box,
    Grid,
  },
})
export default class BaseList extends Vue {
  @Prop({ default: "_id" }) keyField!: string;
  @Prop({ default: 20 }) loadingCount!: number;
  @Prop({ default: null }) sortBy!: string | null;
  @Prop({ default: null }) colsMap!: number[][] | null;
  @Prop({ default: null }) items!: Array<{ [key: string]: any }> | null;

  get options() {
    return this.sortBy
      ? {
          sortBy: [...this.sortBy.split(" ").map((v) => v.replace(/^-/, "") )],
          sortDesc: [...this.sortBy.split(" ").map((v) => v.slice(0, 1) === "-")],
        }
      : {};
  }

  get _colsMap() {
    return (
      this.colsMap || [
        [1300, 6],
        [900, 5],
        [600, 4],
        [450, 3],
        [400, 2],
        [0, 1],
      ]
    );
  }
}
