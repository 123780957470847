import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    scrollBarWidth: 6,
  },
  icons: {
    iconfont: "fa",
  },
  theme: {
    dark: true,
    options: { customProperties: true },
    themes: {
      dark: {
        primary: "#FFAA2B",
        secondary: "#BCB3FF",
      },
    },
  },
});
