
import { Component, Prop, Vue } from "vue-property-decorator";
import defaultBoxImage from "@/assets/default-box.png";
import BaseCard from "@/components/base/BaseCard.vue";
import { BoxEntity } from "@/entities/box.entity";
import Price from "@/components/base/Price.vue";
import GradientBtn from "@/components/base/GradientBtn.vue";

@Component({
  components: {
    BaseCard,
    Price,
    GradientBtn,
  },
})
export default class Box extends Vue {
  @Prop() value!: BoxEntity;
  @Prop({ default: true }) link!: boolean;
  @Prop({ default: "100%" }) imageWidth!: string | number;
  @Prop({ default: 1 }) imageAspectRatio!: number;
  defaultImage = defaultBoxImage;
}
