
import { Component, Prop, Vue } from "vue-property-decorator";
import BaseCard from "@/components/base/BaseCard.vue";
import Price from "@/components/base/Price.vue";
import GradientBtn from "@/components/base/GradientBtn.vue";
import FragmentPrice from "@/components/base/FragmentPrice.vue";
import { IFragmentBox } from "@/interfaces/fragment-box.interface";

@Component({
  components: {
    BaseCard,
    Price,
    FragmentPrice,
    GradientBtn,
  },
})
export default class FragmentBox extends Vue {
  @Prop() value!: IFragmentBox;
  @Prop({ default: 0 }) balance!: number;

  get image() {
    return `/img/fragment-boxes/${this.value.fragmentId.replace(
      "FRAGMENT_",
      ""
    )}_${Math.min(4, this.balance)}.png`;
  }
}
