import { BaseBoxEntity } from "@/entities/base-box.entity";

export class AccountBoxEntity extends BaseBoxEntity {
  accountIds!: string[];
  isAvailable!: boolean;
  __typename = "AccountBox" as const;

  constructor(opts: any) {
    super(opts);
    this.accountIds = opts.accountIds || [];
    this.isAvailable = opts.isAvailable;
  }
}
