
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Config } from "vuescroll";

@Component({})
export default class BaseSlideList extends Vue {
  @Prop({ default: "_id" }) keyField!: string;
  @Prop({ default: 20 }) loadingCount!: number;
  @Prop({ default: null }) sortBy!: string | null;
  @Prop({ default: null }) width!: number | null;
  @Prop({ default: null }) items!: Array<{ [key: string]: any }> | null;
  private scrollOptions: Config = Object.freeze({
    rail: {
      keepShow: false,
    },
    bar: {
      disable: true,
    },
    vuescroll: {
      mode: "slide",
      /* snapping: {
                              enable: true,
                              width: 222.48,
                          },*/
      zooming: false,
      detectResize: false,
    },
    scrollPanel: {
      scrollingX: true,
      scrollingY: false,

      //speed: 222,
    },
  });

  get options() {
    return this.sortBy
      ? {
          sortBy: [
            ...this.sortBy.split(" ").map((sortBy) => sortBy.replace(/^-/, "")),
          ],
          sortDesc: [
            ...this.sortBy
              .split(" ")
              .map((sortBy) => sortBy.slice(0, 1) === "-"),
          ],
        }
      : {};
  }

  @Watch("items.length")
  itemsWatcher() {
    (this.$refs["vs"]! as any).refresh();
  }
}
