<template>
  <component class="animated-number" :is="tag">
    {{ filter(animatedNumber) }}

    <slot v-bind:animatedNumber="animatedNumber"></slot>
  </component>
</template>

<script>
import anime from "animejs";

export default {
  name: "AnimatedNumber",
  props: {
    contentClass: {
      type: [String, Object, Array],
      default: "",
    },
    contentStyle: {
      type: [String, Object, Array],
      default: "",
    },
    tag: {
      type: String,
      default: "span",
    },
    number: {
      type: Number,
      default: 0,
    },
    round: {
      type: Number,
      default: 1,
    },
    duration: {
      type: Number,
      default: 1000,
    },
    filter: {
      type: Function,
      default: (v) => v,
    },
  },
  data() {
    return {
      animatedNumber: 0,
    };
  },
  watch: {
    number() {
      this.animate();
    },
  },
  created() {
    this.animatedNumber = this.number;
  },
  methods: {
    animate() {
      const animation = anime({
        targets: this,
        animatedNumber: this.number,
        round: this.round,
        easing: "linear",
        duration: this.duration,
        complete: () => {
          this.animatedNumber = this.number;
        },
      });
      return animation.finished;
    },
  },
};
</script>

<style scoped></style>
