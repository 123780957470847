
import { Component, Prop, Vue } from "vue-property-decorator";
import BaseList from "@/components/base/BaseList.vue";
import FreeBox from "@/components/boxes/FreeBox.vue";
import { FreeBoxEntity } from "../../entities/free-box.entity";

@Component({
  components: {
    FreeBox,
    BaseList,
  },
})
export default class FreeBoxList extends Vue {
  @Prop() value!: FreeBoxEntity[];
  @Prop({ default: 5 }) loadingCount!: number;
}
