
import { Component, Inject, Vue } from "vue-property-decorator";
import { IS_MOBILE_SYMBOL } from "@/constants";
import Heading from "@/components/base/Heading.vue";
import MobileTopAppBar from "@/components/mobile/MobileTopAppBar.vue";

@Component({
  components: {
    Heading,
    MobileTopAppBar,
  },
})
export default class TopAppBar extends Vue {
  @Inject(IS_MOBILE_SYMBOL) isMobile!: boolean;
}
