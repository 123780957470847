
import { Component, Mixins, Prop, Vue } from "vue-property-decorator";
import BaseSlideList from "@/components/base/BaseSlideList.vue";
import FragmentBox from "@/components/boxes/FragmentBox.vue";
import { IFragmentBox } from "@/interfaces/fragment-box.interface";
import FragmentBoxesBalanceMixin from "@/mixins/fragment-boxes-balance.mixin";

@Component({
  components: {
    FragmentBox,
    BaseSlideList,
  },
})
export default class FragmentBoxSlideList extends Mixins(
  FragmentBoxesBalanceMixin
) {
  @Prop() value!: IFragmentBox[];
  @Prop({ default: 4 }) loadingCount!: number;
}
