import { BaseBoxEntity } from "@/entities/base-box.entity";

export class BoxEntity extends BaseBoxEntity {
  _id!: string;
  image!: string;
  price!: number;
  name!: string;
  isAvailable!: boolean;
  itemsPriceRange!: [number, number];
  __typename = "Box" as const;

  constructor(opts: any) {
    super(opts);
  }
}
