
import { Component, Prop, Vue } from "vue-property-decorator";
import defaultBoxImage from "@/assets/default-box.png";
import BaseCard from "@/components/base/BaseCard.vue";
import { BoxEntity } from "@/entities/box.entity";
import Price from "@/components/base/Price.vue";
import GradientBtn from "@/components/base/GradientBtn.vue";
import {ISnowBox} from "@/interfaces/snow-box.interface";
import SnowPrice from "@/components/base/SnowPrice.vue";

@Component({
  components: {
    BaseCard,
    Price,
    SnowPrice,
    GradientBtn,
  },
})
export default class SnowBox extends Vue {
  @Prop() value!: ISnowBox;

}
