
import {
  Component,
  Prop,
  Ref,
  VModel,
  Vue,
  Watch,
} from "vue-property-decorator";
import VueBottomSheet from "@/components/base/VueBottomSheet.vue";

@Component({
  components: {
    VueBottomSheet,
  },
})
export default class BottomSheet extends Vue {
  @Ref("sheet") el!: any;
  @VModel({ type: Boolean }) show!: boolean;
  @Prop({ default: true }) rounded!: boolean;
  @Prop({ default: false }) overlay!: boolean;
  @Prop({ default: false }) clickToClose!: boolean;
  @Prop({ default: "425px" }) maxWidth!: string;
  @Prop({ default: "90%" }) maxHeight!: string;
  @Prop({ default: "fx-default" }) effect!:
    | "fx-default"
    | "fx-fadein-scale"
    | "fx-slide-from-right"
    | "fx-slide-from-left";

  @Watch("show")
  async showWatcher(value) {
    if (this.el) {
      if (this.show) {
        await this.$nextTick();

        this.el["open"]();
      } else {
        this.el["close"]();
      }
    }
  }

  mounted() {
    this.showWatcher(this.show);
  }

  onClose() {
    this.show = false;
    this.$emit("closed");
  }

  onOpen() {
    this.show = true;
    this.$emit("opened");
  }
}
