
import { Component, Inject, Provide, Vue } from "vue-property-decorator";
import { IS_MOBILE_SYMBOL } from "@/constants";
import logo from "@/assets/logo.png";

@Component({})
export default class BaseFooter extends Vue {
  @Inject(IS_MOBILE_SYMBOL) isMobile!: boolean;

  logo = logo;
}
