
import { Component, Prop, Vue } from "vue-property-decorator";
import BaseCard from "@/components/base/BaseCard.vue";
import Price from "@/components/base/Price.vue";
import GradientBtn from "@/components/base/GradientBtn.vue";
import { GunBoxEntity } from "@/entities/gun-box.entity";

@Component({
  components: {
    BaseCard,
    Price,
    GradientBtn,
  },
})
export default class GunBox extends Vue {
  @Prop() value!: GunBoxEntity;
}
