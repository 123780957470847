
import { Component, InjectReactive, Prop, Vue } from "vue-property-decorator";
import logo from "@/assets/logo.png";
import { USER_SYMBOL } from "@/constants";
import { AuthUserEntity } from "@/entities/user.entity";
import Balance from "@/components/base/Balance.vue";
import GradientBtn from "@/components/base/GradientBtn.vue";

@Component({
  components: {
    Balance,
    GradientBtn,
  },
})
export default class Heading extends Vue {
  @Prop({ default: true }) icon!: boolean;
  @Prop({ default: false }) balance!: boolean;
  @Prop({ default: false, type: Boolean }) bonusBtn!: boolean;
  @Prop() title!: string;
  @Prop() subtitle!: string;
  @InjectReactive(USER_SYMBOL) user!: AuthUserEntity | null;
  logo = logo;
}
