
import { Component, Prop, Vue } from "vue-property-decorator";
import BaseSlideList from "@/components/base/BaseSlideList.vue";
import Box from "@/components/boxes/Box.vue";
import { BoxEntity } from "../../entities/box.entity";

@Component({
  components: {
    Box,
    BaseSlideList,
  },
})
export default class BoxSlideList extends Vue {
  @Prop() value!: BoxEntity[];
  @Prop({ default: 20 }) loadingCount!: number;
}
