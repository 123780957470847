import { Component, Vue } from "vue-property-decorator";
import { FragmentBoxesBalanceFragment } from "@/graphql/fragments";
import { useFindOne } from "@/graphql/use-find-one";
import gql from "graphql-tag";
import { IFragmentBoxesBalance } from "@/interfaces/fragment-boxes-balance.interface";

@Component({
  apollo: {
    fragmentBoxesBalance: {
      query: gql`
        query {
          fragmentBoxesBalance {
            ...FragmentBoxesBalance
          }
        }
        ${FragmentBoxesBalanceFragment}
      `,
      update(data) {
        return data.fragmentBoxesBalance;
      },
      subscribeToMore: {
        updateQuery: (previousResult, { subscriptionData }) => {
          const key = Object.keys(previousResult)[0];

          return {
            [key]: subscriptionData.data.subscribeFragmentBoxesBalanceUpdated,
          };
        },
        document: gql`
          subscription {
            subscribeFragmentBoxesBalanceUpdated {
              ...FragmentBoxesBalance
            }
          }
          ${FragmentBoxesBalanceFragment}
        `,
      },
    },
  },
})
export default class FragmentBoxesBalanceMixin extends Vue {
  fragmentBoxesBalance: IFragmentBoxesBalance | null = null;
}
