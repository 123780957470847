export enum PromoCodeTypeEnum {
  BALANCE_FIX = "BALANCE_FIX",
  DEPOSIT_FIX_BONUS = "DEPOSIT_FIX_BONUS",
  DEPOSIT_PERCENTAGE_BONUS = "DEPOSIT_PERCENTAGE_BONUS",
}

export interface IPromoCode {
  _id: string;
  code: string;
  expireAt: string;
  type: PromoCodeTypeEnum;
  value: number;
}
