
import { Component, Prop, Vue } from "vue-property-decorator";
import AnimatedNumber from "./AnimatedNumber.vue";

@Component({
  components: {
    AnimatedNumber,
  },
})
export default class SnowPrice extends Vue {
  @Prop({ default: undefined }) animated!: boolean | undefined;
  @Prop() value!: number;
  @Prop({ default: undefined }) round!: number;
  @Prop({ default: undefined }) right!: boolean;
  @Prop({ default: true }) icon!: boolean;
  @Prop() iconColor!: string;
  @Prop() iconClass!: string;
}
