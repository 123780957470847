
  import {Component, Inject, Vue, Watch} from "vue-property-decorator";
  import {IS_MOBILE_SYMBOL} from "@/constants";

const THEME_KEY = "APP:THEME";

@Component({})
export default class ThemeToggle extends Vue {
  @Inject(IS_MOBILE_SYMBOL) isMobile!: boolean;
  theme: `new` | `old` = `new`;

  toggleTheme() {
    if (this.theme === `new`) {
      this.theme = "old";
    } else {
      this.theme = "new";
    }
  }

  created() {
    if (this.$localStorage.hasKey(THEME_KEY)) {
      this.theme = this.$localStorage.get(THEME_KEY);
    }

    if(this.isMobile){
      this.theme = 'new';
    }
  }

  @Watch("theme")
  isDarkWatcher(value) {
    this.$localStorage.set(THEME_KEY, this.theme);
    this.$store.commit(`setTheme`, this.theme);
  }
}
