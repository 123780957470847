import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import GunBoxes from "@/views/GunBoxes.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/gun-cases",
    name: "GunBoxes",
    component: GunBoxes,
  },
  {
    path: "/steam/:id",
    name: "SteamRedirect",
    component: () =>
      import(
        /* webpackChunkName: "AppSteamRedirect" */ "../components/app/AppSteamRedirect.vue"
      ),
  },
  {
    path: "/(case|snow-case|fragment-case|free-case|gun-case|account-box)/:id",
    name: "BoxPage",
    component: () =>
      import(/* webpackChunkName: "BoxPage" */ "../views/BoxPage.vue"),
  },
  {
    path: "/usercase",
    name: "UserBox",
    component: () =>
      import(/* webpackChunkName: "UserBox" */ "../views/UserBox.vue"),
  },
  {
    path: "/bonuses",
    name: "BonusPage",
    component: () =>
      import(/* webpackChunkName: "BonusPage" */ "../views/BonusPage.vue"),
  },
  {
    path: "/upgrade/:id?",
    name: "Upgrade",
    component: () =>
      import(/* webpackChunkName: "Upgrade" */ "../views/Upgrade.vue"),
  },
  {
    path: "/battle",
    name: "BattleLobby",
    component: () =>
      import(/* webpackChunkName: "BattleLobby" */ "../views/BattleLobby.vue"),
  },
  {
    path: "/battle/:id",
    name: "BattlePage",
    component: () =>
      import(/* webpackChunkName: "BattlePage" */ "../views/BattlePage.vue"),
  },
  {
    path: "/accounts",
    name: "AccountMarket",
    component: () =>
      import(
        /* webpackChunkName: "AccountMarket" */ "../views/AccountMarket.vue"
      ),
  },
  {
    path: "/shopskins",
    name: "ItemMarket",
    component: () =>
      import(/* webpackChunkName: "ItemMarket" */ "../views/ItemMarket.vue"),
  },

  {
    path: "/profile",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "Profile" */ "../views/Profile.vue"),
  },
  {
    path: "/reviews",
    name: "Reviews",
    component: () =>
      import(/* webpackChunkName: "Reviews" */ "../views/Reviews.vue"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () =>
      import(
        /* webpackChunkName: "PrivacyPolicy" */ "../views/PrivacyPolicy.vue"
      ),
  },
  {
    path: "/terms-of-service",
    name: "TermsOfService",
    component: () =>
      import(
        /* webpackChunkName: "TermsOfService" */ "../views/TermsOfService.vue"
      ),
  },
  {
    path: "/support",
    name: "Support",
    component: () => import(/* webpackChunkName: "FAQ" */ "../views/FAQ.vue"),
  },
  {
    path: "/totp",
    name: "Totp",
    component: () => import(/* webpackChunkName: "Totp" */ "../views/Totp.vue"),
  },
];

const router = new VueRouter({
  routes,
  mode: "history",

  scrollBehavior(to, from, savedPosition) {
    if (!!to.hash || !!from.hash) {
      return;
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
