
import { Component, InjectReactive, Prop, Vue } from "vue-property-decorator";
import { MessageEntity } from "@/entities/message.entity";
import { USER_SYMBOL } from "@/constants";
import { AuthUserEntity, UserEntity } from "@/entities/user.entity";
import gql from "graphql-tag";

@Component({})
export default class AppChatMessage extends Vue {
  @Prop() source!: MessageEntity;
  @InjectReactive(USER_SYMBOL) user!: AuthUserEntity | null;
  private banSecondsCount = 3600;

  get message() {
    return this.source;
  }

  async setUserChatBannedBefore(userId: UserEntity["_id"], seconds: number) {
    await this.$apollo.mutate({
      mutation: gql`
        mutation ($_id: ID!, $chatBannedBefore: Timestamp!) {
          setUserChatBannedBefore(
            chatBannedBefore: $chatBannedBefore
            _id: $_id
          ) {
            _id
          }
        }
      `,
      variables: {
        _id: userId,
        chatBannedBefore: Date.now() + seconds * 1000,
      },
    });
  }

  async removeChatMessagesByUserId(userId1: UserEntity["_id"]) {
    let parent: any = this.$parent?.$parent?.$parent;

    const messagesByUserId =
      parent.messages?.filter(
        ({ userId, deleted }) => userId === userId1 && !deleted
      ) || [];

    for await (const message of messagesByUserId) {
      await this.removeChatMessage(message._id);
    }
  }

  async removeChatMessage(messageId: MessageEntity["_id"]) {
    await this.$apollo.mutate({
      mutation: gql`
        mutation ($_id: ID!) {
          removeChatMessage(_id: $_id) {
            _id
          }
        }
      `,
      variables: {
        _id: messageId,
      },
    });
  }

  reply(message: MessageEntity) {
    if (!this.user || message.user._id === this.user._id) {
      return;
    }
    let parent: any = this.$parent?.$parent?.$parent;

    parent.addText(`@[${message.user.name}]`);
  }
}
