import gql from "graphql-tag";

export function useFindAll(modelName: string, fragment: any) {
    const fragmentName = fragment.definitions[0].name.value;

    return gql`
        query($filter: MongooseFilterDto${modelName}, $options: MongooseFindAllOptionsDto){
            findAll${modelName}(filter: $filter, options: $options){
                ...${fragmentName}
            }
        }
        ${fragment}
    `;
}
