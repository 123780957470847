import { Component, Vue } from "vue-property-decorator";
import { useFindAll } from "@/graphql/use-find-all";
import { GunBoxFragment } from "@/graphql/fragments";
import { GunBoxEntity } from "@/entities/gun-box.entity";

@Component({
  apollo: {
    findAllGunBox: {
      query: useFindAll("GunBox", GunBoxFragment),
      update(data) {
        return data.findAllGunBox || [];
      },
    },
  },
})
export default class FindAllGunBoxMixin extends Vue {
  findAllGunBox: Array<GunBoxEntity> = [];

  get findAllGunBoxMap(): { [key: string]: GunBoxEntity } {
    return this.findAllGunBox.reduce((acc, v) => {
      acc[v._id] = v;

      return acc;
    }, {});
  }

  get gunBoxesByRarity() {
    return this.findAllGunBox
      ? this.findAllGunBox.filter(
          ({ searchType, type }) => searchType === "rarity" || type === "KNIFE"
        )
      : null;
  }

  get gunBoxesByName() {
    return this.findAllGunBox
      ? this.findAllGunBox.filter(
          ({ searchType, type }) => searchType === "name" && type !== "KNIFE"
        )
      : null;
  }

  get gunBoxesCustom() {
    return this.findAllGunBox
      ? this.findAllGunBox.filter(
          ({ searchType }) =>
            searchType === "custom" || searchType === "knifeOrNothing"
        )
      : null;
  }
}
