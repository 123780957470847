import gql from "graphql-tag";
import {
  SnowBoxFragment,
  UserItemWithFromDocumentFragment,
  UserItemWithoutUserFragment,
  UserItemWithUserFragment,
} from "@/graphql/fragments";


export const SnowBoxesQuery = gql`
    query {
      snowBoxes{
        ...SnowBox
      }
    }
    ${SnowBoxFragment}
`

export const PaginateUserItemWithoutUserQuery = gql`
  query ($query: PaginateUserItemsQuery, $options: PaginateOptionsInputType) {
    paginateUserItems(query: $query, options: $options) {
      docs {
        ...UserItemWithoutUser
      }
      nextPage
      totalDocs
    }
  }
  ${UserItemWithoutUserFragment}
`;
export const PaginateUserItemWithUserQuery = gql`
  query ($query: PaginateUserItemsQuery, $options: PaginateOptionsInputType) {
    paginateUserItems(query: $query, options: $options) {
      docs {
        ...UserItemWithUser
      }
      nextPage
      totalDocs
    }
  }
  ${UserItemWithUserFragment}
`;

export const MaxUserItemPriceQuery = gql`
  query {
    maxUserItemPrice
  }
`;

export const PaginateUserItemWithFromDocumentQuery = gql`
  query ($query: PaginateUserItemsQuery, $options: PaginateOptionsInputType) {
    paginateUserItems(query: $query, options: $options) {
      docs {
        ...UserItemWithFromDocument
      }
      nextPage
      totalDocs
    }
  }
  ${UserItemWithFromDocumentFragment}
`;
