
import {
  Component,
  Provide,
  ProvideReactive,
  Vue,
} from "vue-property-decorator";
import Drawer from "@/components/base/Drawer.vue";
import MobileBottomAppBar from "@/components/mobile/MobileBottomAppBar.vue";
import MobileTopAppBar from "@/components/mobile/MobileTopAppBar.vue";
import MobileAppChat from "@/components/mobile/MobileAppChat.vue";
import ThemeToggle from "@/components/base/ThemeToggle.vue";
import {
  API_ENDPOINT_SYMBOL,
  IS_MOBILE_SYMBOL,
  PLATFORM_SYMBOL,
  USER_SYMBOL,
} from "@/constants";
import { AuthUserEntity } from "@/entities/user.entity";
import DrawerRight from "@/components/base/DrawerRight.vue";
import gql from "graphql-tag";
import Authorization from "@/components/base/Authorization.vue";
import { AuthUserFragment, PartialUserFragment } from "@/graphql/fragments";
import Deposit from "@/components/deposit/Deposit.vue";
import BaseFooter from "@/components/base/BaseFooter.vue";
import AppNotifications from "@/components/app/AppNotifications.vue";
import platform from "platform-detect";
import FirebaseMessaging from "@/components/firebase-messaging/FirebaseMessaging.vue";
import SnowFall from "@/components/SnowFall.vue";

@Component({
  components: {
    Drawer,
    FirebaseMessaging,
    Authorization,
    Deposit,
    MobileBottomAppBar,
    MobileAppChat,
    MobileTopAppBar,
    DrawerRight,
    BaseFooter,
    AppNotifications,
    ThemeToggle,
    SnowFall,
  },
  metaInfo: {
    title: "Открытие кейсов кс го со 100% окупаемостью",
    titleTemplate: `Buyskins | %s`,
  },
  apollo: {
    user: {
      update(data) {
        return data.user ? new AuthUserEntity(data.user) : null;
      },
      query: gql`
        query {
          user {
            ...AuthUser
          }
        }
        ${AuthUserFragment}
      `,
      subscribeToMore: {
        skip() {
          return !this.user;
        },
        updateQuery: (previousResult, { subscriptionData }) => {
          const update = Object.fromEntries(
            Object.entries(subscriptionData.data.subscribeUserUpdated).filter(
              ([key, value]) => key !== "__typename" && value !== null
            )
          );
          previousResult.user = Object.assign(previousResult.user, update);
          return previousResult;
        },
        document: gql`
          subscription {
            subscribeUserUpdated {
              ...PartialUser
            }
          }
          ${PartialUserFragment}
        `,
      },
    },
  },
})
export default class App extends Vue {
  @Provide(API_ENDPOINT_SYMBOL) apiEndpoint: string =
    process.env.VUE_APP_API_ENDPOINT.replace(
      "[hostname]",
      window.location.hostname
    );
  @Provide(PLATFORM_SYMBOL) platform: {
    windows: boolean;
    linux: boolean;
    android: boolean;
    macos: boolean;
    ios: boolean;
  } = platform;
  @ProvideReactive(USER_SYMBOL) user: AuthUserEntity | null = null;
  @Provide(IS_MOBILE_SYMBOL) isMobile: boolean =
    this.$vuetify.breakpoint.mobile;
  private serverConnectionError = false;
  private showTempDialog = true;

  created() {
    // Если сервер недоступен
    setTimeout(() => {
      if (this.$apollo.queries.user.loading) {
        this.serverConnectionError = true;
      }
    }, 10000);
  }
}
