
import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import {
  getElementScrollPercent,
  getWindowScrollPercent,
  mergeDeep,
} from "@/helpers";
import { SmartQuery } from "vue-apollo/types/vue-apollo";

@Component({})
export default class ScrollFetchMore extends Vue {
  @Prop() query!: SmartQuery<any>;
  @Prop({ default: undefined }) reverse: boolean | undefined;
  @Prop({ default: "scroll" }) mode!: "scroll" | "findAll";
  @Prop({ default: undefined }) keepScrollPosition: boolean | undefined;
  @Prop() scrollTarget!: string | undefined;
  @Prop({ default: undefined }) unshift!: boolean | undefined;
  @Prop({ default: () => ({ options: {} }) }) variables!: {
    options?: any;
    query?: any;
  };
  @PropSync("nextPage", { default: () => null }) _nextPage!: number | null;
  public loading = false;
  public lastFindAllLength: number | null = null;
  public lastFindAllVariables: string | null = null;

  get getScrollPercent(): () => number {
    return this.scrollTarget
      ? getElementScrollPercent.bind(this, this.scrollTarget)
      : getWindowScrollPercent;
  }

  async onScroll() {
    await this.$nextTick();

    if (
      this.reverse === undefined
        ? this.getScrollPercent() > 80
        : this.getScrollPercent() < 20
    ) {
      this[
        this.mode === "findAll" ? "fetchMoreFindAll" : "fetchMoreScroll"
      ]().catch(console.error);
    }
  }

  async fetchMoreScroll() {
    const nextScrollToken =
      this.query["observer"].lastResult.data[
        Object.keys(this.query["observer"].lastResult.data)[0]
      ].nextScrollToken;

    if (this.loading || !nextScrollToken) {
      return [];
    }

    this.loading = true;

    const variables = mergeDeep({}, this.query["lastApolloOptions"].variables, {
      options: {
        nextScrollToken,
      },
    });

    let scrollTarget = this.scrollTarget
        ? document.querySelector(this.scrollTarget!)!
        : null,
      oldScroll = 0,
      currentScrollPosition = 0;

    if (scrollTarget && this.keepScrollPosition !== undefined) {
      oldScroll = scrollTarget.scrollHeight - scrollTarget.clientHeight;
      currentScrollPosition = scrollTarget.scrollTop;
    }

    let newDocs = [];
    await this.query.fetchMore({
      variables,
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const key = Object.keys(previousResult)[0];

        newDocs = fetchMoreResult[key].docs;

        if (typeof this.unshift === "undefined") {
          previousResult[key].docs = previousResult[key].docs.concat(newDocs);
        } else {
          previousResult[key].docs = newDocs.concat(previousResult[key].docs);
        }

        previousResult[key].nextScrollToken =
          fetchMoreResult[key].nextScrollToken;

        return previousResult;
      },
    });

    this.$emit("added", newDocs);

    await this.$nextTick();

    if (scrollTarget && this.keepScrollPosition !== undefined) {
      const newScroll = scrollTarget.scrollHeight - scrollTarget.clientHeight;
      scrollTarget.scrollTop = currentScrollPosition + (newScroll - oldScroll);
    }

    this.loading = false;
  }

  async fetchMoreFindAll() {
    let variables = this.query["lastApolloOptions"].variables;
    const limit =
      variables.options && typeof variables.options.limit === "number"
        ? variables.options.limit
        : 25;

    const totalDocs =
      this.query["observer"].lastResult.data[
        Object.keys(this.query["observer"].lastResult.data)[0]
      ].length;

    variables = mergeDeep({}, this.query["lastApolloOptions"].variables, {
      options: {
        skip: totalDocs,
      },
    });

    if (JSON.stringify(variables) !== this.lastFindAllVariables) {
      this.lastFindAllLength = null;
    }

    if (
      this.loading ||
      (this.lastFindAllLength && this.lastFindAllLength < limit) ||
      this.lastFindAllLength === 0
    ) {
      return [];
    }

    this.loading = true;

    this.lastFindAllVariables = JSON.stringify(variables);

    let scrollTarget = this.scrollTarget
        ? document.querySelector(this.scrollTarget!)!
        : null,
      oldScroll = 0,
      currentScrollPosition = 0;

    if (scrollTarget && this.keepScrollPosition !== undefined) {
      oldScroll = scrollTarget.scrollHeight - scrollTarget.clientHeight;
      currentScrollPosition = scrollTarget.scrollTop;
    }

    let newDocs = [];
    await this.query.fetchMore({
      variables,
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const key = Object.keys(previousResult)[0];

        newDocs = fetchMoreResult[key];

        if (typeof this.unshift === "undefined") {
          previousResult[key] = previousResult[key].concat(newDocs);
        } else {
          previousResult[key] = newDocs.concat(previousResult[key]);
        }

        return { [key]: [...previousResult[key]] };
      },
    });
    this.lastFindAllLength = newDocs.length;

    this.$emit("added", newDocs);

    await this.$nextTick();

    if (scrollTarget && this.keepScrollPosition !== undefined) {
      const newScroll = scrollTarget.scrollHeight - scrollTarget.clientHeight;
      scrollTarget.scrollTop = currentScrollPosition + (newScroll - oldScroll);
    }

    this.loading = false;
  }
}
