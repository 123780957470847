
import { Component, Prop, Vue } from "vue-property-decorator";
import Price from "./Price.vue";
import SnowPrice from "@/components/base/SnowPrice.vue";
import BonusPrice from "@/components/BonusPrice.vue";

@Component({
  components: {
    BonusPrice,
    Price,
    SnowPrice,
  },
})
export default class Balance extends Vue {
  @Prop() value!: number;
  @Prop() snowValue!: number;
  @Prop() bonusValue!: number;
  @Prop({ default: false }) flat!: boolean;
}
