
import {Component, Prop, Vue} from "vue-property-decorator";
import BaseList from "@/components/base/BaseList.vue";
import SnowBox from "@/components/boxes/SnowBox.vue";
import {ISnowBox} from "@/interfaces/snow-box.interface";

@Component({
  components: {
    SnowBox,
    BaseList,
  },
})
export default class SnowBoxList extends Vue {
  @Prop() value!: ISnowBox[];
  @Prop({default: 3}) loadingCount!: number;
}
