
import { Component, Vue } from "vue-property-decorator";
import Item from "@/components/items/Item.vue";
import ScrollFetchMore from "@/components/base/ScrollFetchMore.vue";
import { UserItemEntity } from "@/entities/user-item.entity";
import { PaginateUserItemWithUserQuery } from "@/graphql/queries";
import gql from "graphql-tag";
import { UserItemWithUserFragment } from "@/graphql/fragments";
import BaseSlideList from "@/components/base/BaseSlideList.vue";
import { useScroll } from "@/graphql/use-scroll";
import { useFindAll } from "@/graphql/use-find-all";

@Component({
  components: {
    BaseSlideList,
    ScrollFetchMore,
    Item,
  },
  apollo: {
    items: {
      update(data) {
        return data.findAllUserItem
          ? data.findAllUserItem.map((item, index) => {
              return new UserItemEntity(item);
            })
          : null;
      },
      query: useFindAll("UserItem", UserItemWithUserFragment),
      variables() {
        return {
          options: {
            sort: "-receivedAt",
            populate: "user fromDocument",
            limit: 25,
          },
          filter: {
            userId: { exists: true },
          },
        };
      },
      subscribeToMore: {
        updateQuery(previousResult, { subscriptionData }) {
          const key = Object.keys(previousResult)[0];
          const item = new UserItemEntity(
            subscriptionData.data.subscribeUserItemAdded
          );

          return {
            [key]: [item, ...previousResult[key].docs],
          };
        },
        document: gql`
          subscription {
            subscribeUserItemAdded {
              ...UserItemWithUser
            }
          }
          ${UserItemWithUserFragment}
        `,
      },
    },
  },
})
export default class LiveDrop extends Vue {
  private items: UserItemEntity[] | null = null;
}
