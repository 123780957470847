import { Component, Vue } from "vue-property-decorator";
import { useFindAll } from "@/graphql/use-find-all";
import { GunBoxFragment } from "@/graphql/fragments";
import { GunBoxEntity } from "@/entities/gun-box.entity";
import gql from "graphql-tag";
import {
  IPromoCode,
  PromoCodeTypeEnum,
} from "@/interfaces/promo-code.interface";

@Component({
  apollo: {
    promoCodeData: {
      update(data) {
        this.promoCodeError = "";

        return data.canActivatePromoCode;
      },
      skip() {
        const skip = !this.promoCode;

        if (skip) {
          this.promoCodeError = "";
          this.promoCodeData = null;
        }

        return skip;
      },
      query: gql`
        query ($input: CanActivatePromoCodeInput!) {
          canActivatePromoCode(input: $input) {
            _id
            code
            expireAt
            type
            value
          }
        }
      `,
      fetchPolicy: "no-cache",
      error(e) {
        if (e.message.indexOf("not found") !== -1) {
          this.promoCodeError = "Промокода не существует";
        } else if (e.message.indexOf("activated") !== -1) {
          this.promoCodeError = "Промокод уже был активирован";
        } else if (e.message.indexOf("expired") !== -1) {
          this.promoCodeError = "Истек срок действия промокода";
        } else if (e.message.indexOf("exhausted") !== -1) {
          this.promoCodeError = "Промокод закончился";
        } else if (e.message.indexOf("minDepositGetAmount") !== -1) {
          const minDepositGetAmount = /(\d+(\.?\d+)?)$/.exec(e.message);

          this.promoCodeError = `Сумма зачисления должна быть не меньше ${
            minDepositGetAmount ? minDepositGetAmount[1] : "0"
          }`;
        } else if (e.message.indexOf("minUserDepositsSum") !== -1) {
          const minUserDepositsSum = /(\d+(\.?\d+)?)$/.exec(e.message);
          this.promoCodeError = `Пополните баланс на ${
            minUserDepositsSum ? minUserDepositsSum[1] : "0"
          } для активации`;
        } else {
          this.promoCodeError = `Серверная ошибка, обратитесь в поддержку`;
        }

        this.promoCodeData = null;
      },
      variables() {
        return {
          input: {
            code: this.promoCode,
            ...(this.getAmount ? { depositGetAmount: this.getAmount } : {}),
          },
        };
      },
    },
  },
})
export default class PromoCodeMixin extends Vue {
  getAmount!: number | null;
  loading!: boolean;
  promoCode = "";
  promoCodeError = "";
  promoCodeData: IPromoCode | null = null;

  get promoCodeTypeLabel() {
    if (!this.promoCodeData) {
      return "";
    }

    switch (this.promoCodeData.type) {
      case PromoCodeTypeEnum.DEPOSIT_PERCENTAGE_BONUS:
        return `+${
          this.promoCodeData.value * 100
        }% от депозита на бонусный баланс`;
      case PromoCodeTypeEnum.DEPOSIT_FIX_BONUS:
        return `+${this.promoCodeData.value} к депозиту на бонусный баланс`;
      case PromoCodeTypeEnum.BALANCE_FIX:
        return `+${this.promoCodeData.value} на баланс`;
      default:
        return "";
    }
  }

  async activatePromoCode() {
    this.loading = true;

    const promoCode = await this.$apollo
      .mutate({
        mutation: gql`
          mutation ($input: ActivatePromoCodeInput!) {
            activatePromoCode(input: $input) {
              _id
              code
              expireAt
              type
              value
            }
          }
        `,
        variables: {
          input: {
            code: this.promoCode,
          },
        },
      })
      .then(({ data }) => data.activatePromoCode)
      .catch(() => null);

    this.loading = false;

    if (!promoCode) {
      this.$notify({
        type: "error",
        text: `Не удалось активировать промокод. Обратитесь в поддержку.`,
      });
      return;
    }

    this.$notify({
      type: "success",
      text: `Промокод активирован.`,
    });

    this.promoCode = "";
  }
}
