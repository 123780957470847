import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { createProvider } from "./plugins/vue-apollo";
import "./styles/app.scss";
import VueSvgInlinePlugin from "vue-svg-inline-plugin";
import vuescroll from "vuescroll";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import VueVirtualScroller from "vue-virtual-scroller";
import Notifications from "vue-notification";
import StoragePlugin from "vue-web-storage";
import VueAwesomeCountdown from "vue-awesome-countdown";
import ReadMore from "vue-read-more";
import VueMask from "v-mask";
import vueNumeralFilterInstaller from "vue-numeral-filter";
import VueMeta from 'vue-meta'
import VueClipboard from 'vue-clipboard2'

const moment = require("moment");
require("moment/locale/ru");

Vue.use(require("vue-moment"), {
  moment,
});
Vue.use(VueMeta);
Vue.use(vueNumeralFilterInstaller, { locale: "ru" });
Vue.use(VueMask);
Vue.use(ReadMore);
Vue.use(VueAwesomeCountdown, "vac");
Vue.use(StoragePlugin);
Vue.use(Notifications);
Vue.use(VueVirtualScroller);
Vue.use(VueClipboard)

// You can set global config here.
Vue.use(vuescroll, {
  ops: {},
});
Vue.use(VueSvgInlinePlugin);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  apolloProvider: createProvider(),
  render: (h) => h(App),
}).$mount("#app");
