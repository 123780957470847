import { UserEntity } from "@/entities/user.entity";
import * as escape from 'lodash/escape'
import * as unescape from 'lodash/unescape'
import DOMPurify from 'dompurify';

function textToHtml(text: string, trusted: boolean): {html: string, mentions: string[]} {
  const mentions: string[] = [], html = (trusted === true ? DOMPurify.sanitize(text) : escape(text)).replace(/@\[.+\]/gi, match => {
    const mention: string = match.slice(2, -1);
    const unescapeMention: string = unescape(mention);

    if(mentions.indexOf(unescapeMention) === -1){
      mentions.push(unescapeMention);
    }
    return `<span class="mentioning">@${mention}</span>`;
  });

  return {mentions, html}
}

export class MessageEntity {
  _id: string;
  text: string;
  html: string;
  mentions: string[];
  user: UserEntity;
  userId: string;
  deleted: boolean;
  trusted: boolean;
  __typename = "ChatMessage" as const;

  constructor({ _id, text, user, deleted, userId, trusted }: any) {
    this.deleted = !!deleted;
    this.trusted = !!trusted;
    this._id = _id;
    this.text = text;
    this.userId = userId;
    this.user = new UserEntity(user);
    const {html, mentions} = textToHtml(this.text, this.trusted);
    this.html = html;
    this.mentions = mentions;
  }
}
