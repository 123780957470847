
import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import { API_ENDPOINT_SYMBOL } from "@/constants";

const REDIRECT_KEY = "login_redirect_path";

@Component({})
export default class Authorization extends Vue {
  @Inject(API_ENDPOINT_SYMBOL) apiEndpoint!: string;

  created() {
    if (this.$localStorage.hasKey(REDIRECT_KEY)) {
      const path: string = this.$localStorage.get(REDIRECT_KEY);
      this.$localStorage.remove(REDIRECT_KEY);
      if (path !== "/") {
        this.$router.replace(path);
      }
    }
  }

  @Watch(`$route.hash`, { immediate: true })
  watchRouteHash(value) {
    const isCom = window.location.hostname.indexOf(".com") !== -1;

    if (value === "#login") {
      this.$router.replace(this.$route.path);
      this.$localStorage.set(REDIRECT_KEY, this.$route.path);
      document.location.href =
        this.apiEndpoint + `auth/${isCom ? "steam-com" : "steam"}`;
    } else if (value === "#logout") {
      this.$router.replace(this.$route.path);
      document.location.href = this.apiEndpoint + "auth/logout";
    }
  }
}
