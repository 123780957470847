export enum ESteamItemRarity {
  GREY = "GREY",
  LIGHT_BLUE = "LIGHT_BLUE",
  DARKER_BLUE = "DARKER_BLUE",
  PINKISH_PURPLE = "PINKISH_PURPLE",
  PURPLE = "PURPLE",
  RED = "RED",
  GOLD = "GOLD",
}

export enum ESteamItemExterior {
  "FN" = "Factory New",
  "MW" = "Minimal Wear",
  "FT" = "Field-Tested",
  "WW" = "Well-Worn",
  "BS" = "Battle-Scarred",
}

const SteamItemExteriors = Object.values(ESteamItemExterior);

export class SteamItemEntity {
  _id!: string;
  name!: string;
  image!: string;
  rarity!: ESteamItemRarity;
  price!: number;
  __typename = "SteamItem";

  constructor(opts: any) {
    Object.keys(opts).forEach((key) => {
      this[key] = opts[key];
    });
  }

  get nameShort(): string {
    if (!this.name) {
      return "";
    }

    const nameShort = this.name.split(" | ")[1] || this.name;

    return nameShort
      .replace(/\(.+\)$/, "")
      .replace("StatTrak™", "")
      .trim();
  }

  get statTrack(): boolean {
    return this.name.indexOf("StatTrak™") !== -1;
  }

  get weaponType(): string {
    return this.name.indexOf("|") === -1
      ? ""
      : this.name.replace("StatTrak™", "").split(" | ")[0];
  }

  get exteriorShort(): string {
    if (!this.exterior) {
      return "";
    }

    return this.exterior.replace(/[^A-Z]/g, "");
  }

  get exterior(): string {
    if (!this.name) {
      return "";
    }

    const quality = this.name.match(/\(.+\)$/);
    const result = quality ? quality[0].replace("(", "").replace(")", "") : "";

    return SteamItemExteriors.includes(result as ESteamItemExterior)
      ? result
      : "";
  }
}
