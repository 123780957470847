
import { Component, Prop, Vue } from "vue-property-decorator";
import GradientBtn from "@/components/base/GradientBtn.vue";

@Component({
  components: {
    GradientBtn,
  },
})
export default class BaseCard extends Vue {
  @Prop() image!: string;
  @Prop({ default: "100%" }) imageWidth!: string;
  @Prop({ default: true }) link!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: undefined }) to!: string;
  @Prop({ default: undefined }) imageAspectRatio!: number;
}
