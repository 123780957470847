
import { Component, Prop, Vue } from "vue-property-decorator";
import { FreeBoxEntity } from "../../entities/free-box.entity";
import BaseSlideList from "@/components/base/BaseSlideList.vue";
import FreeBox from "@/components/boxes/FreeBox.vue";

@Component({
  components: {
    FreeBox,
    BaseSlideList,
  },
})
export default class FreeBoxSlideList extends Vue {
  @Prop() value!: FreeBoxEntity[];
  @Prop({ default: 5 }) loadingCount!: number;
}
