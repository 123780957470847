export class FreeBoxEntity {
  _id!: string;
  image?: string;
  name?: string;
  itemIds!: string[];
  isAvailable!: boolean;
  levelPoints!: number;
  level!: number;
  frequencyInMilliseconds!: number;

  constructor(opts) {
    Object.assign(this, opts);
  }
}
