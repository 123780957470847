
import { Component, Inject, Vue } from "vue-property-decorator";
import { IS_MOBILE_SYMBOL } from "@/constants";

@Component({})
export default class AppNotifications extends Vue {
  @Inject(IS_MOBILE_SYMBOL) isMobile!: boolean;

  get bottomHeight() {
    return this.$vuetify.application.bottom;
  }

  get leftWidth() {
    return this.$vuetify.application.left;
  }
}
