export enum UserRoleEnum {
  USER = "USER",
  MODERATOR = "MODERATOR",
  ADMINISTRATOR = "ADMINISTRATOR",
}

export class UserEntity {
  _id!: string;
  name!: string;
  avatar!: string;
  level?: number;
  balance?: number;
  snowBalance?: number;
  bonusBalance?: number;
  email?: string;
  tradeUrl?: string;
  createdAt?: string;
  levelPoints?: number;
  nextLevelPoints?: number;
  prevLevelPoints?: number;
  role?: UserRoleEnum;
  depositsSum?: number;
  withdrawalsSum?: number;
  upgradesCount?: number;
  boxesCount?: number;
  freeBoxesCount?: number;
  userBoxesCount?: number;
  accountBoxesCount?: number;
  getTelegramBonus?: boolean;
  chatBannedBefore?: number;
  __typename = "User" as const;

  constructor(opts: any) {
    Object.assign(this, opts);
  }

  get openBoxesCount() {
    return (
      (this.boxesCount || 0) +
      (this.userBoxesCount || 0) +
      (this.freeBoxesCount || 0) +
      (this.accountBoxesCount || 0)
    );
  }

  get playedGamesCount() {
    return this.upgradesCount || 0;
  }
}

export class AuthUserEntity extends UserEntity {
  _id!: string;
  name!: string;
  level!: number;
  avatar!: string;
  balance!: number;
  snowBalance!: number;
  bonusBalance!: number;
  email?: string;
  createdAt!: string;
  tradeUrl!: string;
  levelPoints!: number;
  nextLevelPoints!: number;
  prevLevelPoints!: number;
  role!: UserRoleEnum;
  depositsSum!: number;
  withdrawalsSum!: number;
  upgradesCount!: number;
  accountBoxesCount!: number;
  boxesCount!: number;
  freeBoxesCount!: number;
  getTelegramBonus!: boolean;
  skipUpgradeConfirmation!: boolean;
  userBoxesCount!: number;
  chatBannedBefore?: number;
  __typename = "User" as const;

  get isModerator() {
    return [UserRoleEnum.MODERATOR, UserRoleEnum.ADMINISTRATOR].includes(
      this.role
    );
  }

  constructor(opts: any) {
    super(opts);
  }
}
