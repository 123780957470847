
import { Component, Prop, Vue } from "vue-property-decorator";
import BaseSlideList from "@/components/base/BaseSlideList.vue";
import GunBox from "@/components/boxes/GunBox.vue";
import { GunBoxEntity } from "@/entities/gun-box.entity";

@Component({
  components: {
    GunBox,
    BaseSlideList,
  },
})
export default class GunBoxSlideList extends Vue {
  @Prop() value!: GunBoxEntity[];
  @Prop({ default: 5 }) loadingCount!: number;
}
