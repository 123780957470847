
import { Component, Prop, Vue } from "vue-property-decorator";
import GunBox from "@/components/boxes/GunBox.vue";
import BaseList from "@/components/base/BaseList.vue";
import { GunBoxEntity } from "@/entities/gun-box.entity";

@Component({
  components: {
    GunBox,
    BaseList,
  },
})
export default class GunBoxList extends Vue {
  @Prop() value!: GunBoxEntity[];
  @Prop({ default: 5 }) loadingCount!: number;
}
