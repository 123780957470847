
import { Component, InjectReactive, Vue } from "vue-property-decorator";
import {
  VAPID_KEY,
  WEB_PUSH_PROPOSAL_TIME,
} from "@/components/firebase-messaging/constants";
import { v4 as uuidv4 } from "uuid";
import gql from "graphql-tag";
import logo from "@/assets/logo.png";
import { USER_SYMBOL } from "@/constants";
import { AuthUserEntity } from "@/entities/user.entity";

declare global {
  export const firebase: any;
}

@Component({})
export default class FirebaseMessaging extends Vue {
  logo = Object.freeze(logo);
  @InjectReactive(USER_SYMBOL) user!: AuthUserEntity | null;
  private deviceId: string = this.$localStorage.get("deviceId") || uuidv4();
  private firebaseToken: string = this.$localStorage.get("firebaseToken") || ``;
  private lastWebPushProposalAt: number =
    this.$localStorage.get("lastWebPushProposalAt") || 0;
  private lastWebPushSaveUserId: string =
    this.$localStorage.get("lastWebPushSaveUserId") || "";
  private show = false;
  private loading = false;

  created() {
    if (!this.$localStorage.hasKey("deviceId")) {
      this.$localStorage.set("deviceId", this.deviceId);
    }

    if (!this.$localStorage.hasKey("firebaseToken")) {
      this.$localStorage.set("firebaseToken", "");
    }

    if (!this.$localStorage.hasKey("lastWebPushProposalAt")) {
      this.$localStorage.set("lastWebPushProposalAt", 0);
    }

    if (!Notification) {
      return;
    }

    if (Notification.permission === "granted") {
      if (!this.firebaseToken) {
        this.registerFirebaseToken().catch((e) =>
          console.error(`registerFirebaseToken: ${e.message}`)
        );
      } else if (this.user && this.lastWebPushSaveUserId !== this.user._id) {
        this.registerFirebaseToken(this.firebaseToken).catch((e) =>
          console.error(`registerFirebaseToken: ${e.message}`)
        );
      }

      firebase.messaging().onMessage((payload) => {
        if (!payload.data.icon) {
          payload.data.icon = "/img/icons/apple-touch-icon-152x152.png";
        }

        payload.data.data = JSON.parse(JSON.stringify(payload.data));
        console.log("[firebase-messaging-sw.js] Received message ", payload);
        this.showLocalNotification(payload);
      });

      firebase.messaging().onTokenRefresh(() => {
        this.registerFirebaseToken().catch((e) =>
          console.error(`refreshFirebaseToken: ${e.message}`)
        );
      });
    } else if (
      Notification.permission === "default" &&
      Date.now() - this.lastWebPushProposalAt > WEB_PUSH_PROPOSAL_TIME
    ) {
      this.showWebPushProposal();
    }
  }

  async remindMeLater() {
    this.$localStorage.set("lastWebPushProposalAt", Date.now());
    this.lastWebPushProposalAt = Date.now();
    this.show = false;
  }

  async showWebPushProposal() {
    this.show = true;
  }

  async showLocalNotification(payload: any) {
    this.$notify({
      text: payload.data.body,
      title: payload.data.title,
      data: {
        image: payload.data.icon || payload.data.image,
        actions: payload.data.url
          ? [
              {
                label: `Перейти`,
                handler: () => window.open(payload.data.url, "_blank"),
              },
            ]
          : undefined,
      },
    });
  }

  async registerFirebaseToken(oldToken?: string) {
    this.loading = true;
    const token =
      oldToken || (await this.requestFirebaseToken().catch(console.error));

    await this.$apollo
      .mutate({
        mutation: gql`
          mutation registerFirebaseToken($input: FirebaseTokenInput!) {
            registerFirebaseToken(input: $input)
          }
        `,
        variables: {
          input: {
            deviceId: this.deviceId,
            token,
          },
        },
      })
      .then(() => {
        if (this.user) {
          this.$localStorage.set("lastWebPushSaveUserId", this.user._id);
        }
      })
      .catch(console.error);

    this.loading = false;
    this.show = false;
  }

  async requestFirebaseToken() {
    //@ts-ignore
    const token = await firebase.messaging().getToken({ vapidKey: VAPID_KEY });
    this.$localStorage.set("firebaseToken", token);
    this.firebaseToken = this.$localStorage.get("firebaseToken");

    return token;
  }
}
