
import { Component, VModel, Vue } from "vue-property-decorator";
import DepositContent from "@/components/deposit/DepositContent.vue";

@Component({
  components: {
    DepositContent,
  },
})
export default class DepositDialog extends Vue {
  @VModel({ type: Boolean }) show!: boolean;
}
