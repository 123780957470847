import { SteamItemEntity } from "@/entities/steam-item.entity";
import { UserEntity } from "@/entities/user.entity";

export enum FromDocumentTypeEnum {
  BOX = "BOX",
  UPGRADE = "UPGRADE",
  USER_BOX = "USER_BOX",
  USER_ITEM = "USER_ITEM",
  FREE_BOX = "FREE_BOX",
  GUN_BOX = "GUN_BOX",
  SNOW_BOX = "SNOW_BOX",
  FRAGMENT_BOX = "FRAGMENT_BOX",
  BATTLE = "BATTLE",
}

export const FROM_DOCUMENT_LINK = {
  BOX: (fromDocument) => `/case/${fromDocument._id}`,
  UPGRADE: (fromDocument) => `/upgrade`,
  USER_BOX: (fromDocument) => `/usercase`,
  BATTLE: (fromDocument) => `/battle/${fromDocument._id}`,
  USER_ITEM: (fromDocument) => `/shopskins`,
  FREE_BOX: (fromDocument) => `/free-case/${fromDocument._id}`,
  GUN_BOX: (fromDocument) => `/gun-case/${fromDocument._id}`,
  SNOW_BOX: (fromDocument) => `/snow-case/${fromDocument._id}`,
  FRAGMENT_BOX: (fromDocument) => `/fragment-case/${fromDocument._id}`,
};

export class UserItemEntity extends SteamItemEntity {
  user?: UserEntity;
  userId!: UserEntity["_id"];
  unExistsOnSkinsPlus?: boolean;
  fromDocumentType!: FromDocumentTypeEnum;
  fragmentId?: string;
  fromDocument?: { _id: string; image?: string; name?: string };
  __typename = "UserItem" as const;

  constructor(opts: any) {
    super(Object.assign({}, opts, { __typename: undefined }));

    Object.keys(opts).forEach((key) => {
      if (key === "user") {
        this[key] = new UserEntity(opts[key] as UserEntity);
      }

      this[key] = opts[key];
    });
  }

  get fromDocumentLink() {
    return this.fromDocument
      ? FROM_DOCUMENT_LINK[this.fromDocumentType](this.fromDocument)
      : undefined;
  }
}
