/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
  return item && typeof item === "object" && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export function getWindowScrollPercent() {
  const h = document.documentElement,
    b = document.body,
    st = "scrollTop",
    sh = "scrollHeight";
  return ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
}

export function getElementScrollPercent(selector: string) {
  const containeR = document.querySelector(selector)!;

  return (
    (100 * containeR.scrollTop) /
    (containeR.scrollHeight - containeR.clientHeight)
  );
}

export function getElementScrollHeight(selector: string) {
  const containeR = document.querySelector(selector)!;

  if (!containeR) {
    return 0;
  }

  return containeR.scrollHeight - containeR.clientHeight;
}

export function getElementScrollPosition(selector: string) {
  const containeR = document.querySelector(selector)!;

  if (!containeR) {
    return 0;
  }

  return containeR.scrollTop;
}
