
import { Component, Prop, Vue } from "vue-property-decorator";
import sortBy from "lodash/sortBy";

@Component({})
export default class Grid extends Vue {
  @Prop({
    type: Array,
    default() {
      return [];
    },
  })
  colsMap!: Array<[number, number]>;
  @Prop({ type: Number, default: 16 }) gridRowGap!: number;
  @Prop({ type: Number, default: 16 }) gridColumnGap!: number;
  private cols = 4;

  get sortedColsMap() {
    return sortBy(this.colsMap, "0").reverse();
  }

  mounted() {
    this.onResize();
  }

  async onResize() {
    await this.$nextTick();
    const elWidth = this.$el.clientWidth;
    let result;
    for (const [width, cols] of this.sortedColsMap) {
      if (elWidth >= width) {
        result = cols;
        break;
      }
    }
    if (!result && this.sortedColsMap.length) {
      result = this.sortedColsMap[this.sortedColsMap.length - 1][1];
    }
    this.cols = result || this.cols;
  }
}
