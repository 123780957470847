
import { Component, Vue, Watch } from "vue-property-decorator";
import AppChat from "@/components/app/AppChat.vue";

@Component({
  components: {
    AppChat,
  },
})
export default class MobileAppChat extends Vue {
  private show = false;

  @Watch(`$route.hash`, { immediate: true })
  watchRouteHash(value) {
    if (value === "#chat") {
      this.show = true;
    } else if (this.show) {
      this.show = false;
    }
  }

  @Watch(`show`)
  watchShow(value) {
    if (!value && this.$route.hash === "#chat") {
      this.$router.replace(this.$route.path);
    }
  }
}
