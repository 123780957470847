
import { Component, Inject, Mixins, Vue } from "vue-property-decorator";
import { GunBoxFragment } from "@/graphql/fragments";
import { IS_MOBILE_SYMBOL } from "@/constants";
import { useFindAll } from "@/graphql/use-find-all";
import GunBoxList from "@/components/boxes/GunBoxList.vue";
import GunBoxSlideList from "@/components/boxes/GunBoxSlideList.vue";
import { GunBoxEntity } from "@/entities/gun-box.entity";
import TopAppBar from "@/components/base/TopAppBar.vue";
import FindAllGunBoxMixin from "@/mixins/find-all-gun-box.mixin";

@Component({
  components: {
    GunBoxList,
    GunBoxSlideList,
    TopAppBar,
  },
})
export default class GunBoxes extends Mixins(FindAllGunBoxMixin) {
  @Inject(IS_MOBILE_SYMBOL) isMobile!: boolean;
}
