
import { Component, Vue } from "vue-property-decorator";
import star from "../../assets/drawer-icons/star.svg";
import flash from "../../assets/drawer-icons/flash.svg";
import home from "../../assets/drawer-icons/home.svg";
import market from "../../assets/drawer-icons/market.svg";
import activeBg from "../../assets/drawer-icons/active-bg.svg";
import group from "../../assets/drawer-icons/group.svg";

import caseOn from "../../assets/drawer-icons/old/cases_on.png";
import caseOff from "../../assets/drawer-icons/old/cases_off.png";
import accountsOn from "../../assets/drawer-icons/old/accounts_on.png";
import accountsOff from "../../assets/drawer-icons/old/accounts_off.png";
import transactionsOn from "../../assets/drawer-icons/old/transactions_on.png";
import transactionsOff from "../../assets/drawer-icons/old/transactions_off.png";
import inventoryOn from "../../assets/drawer-icons/old/inventory_on.png";
import inventoryOff from "../../assets/drawer-icons/old/inventory_off.png";
import shopskinsOn from "../../assets/drawer-icons/old/shopskins_on.png";
import shopskinsOff from "../../assets/drawer-icons/old/shopskins_off.png";
import supportOn from "../../assets/drawer-icons/old/support_on.png";
import supportOff from "../../assets/drawer-icons/old/support_off.png";
import upgradeOn from "../../assets/drawer-icons/old/upgrade_on.png";
import upgradeOff from "../../assets/drawer-icons/old/upgrade_off.png";
import usercaseOn from "../../assets/drawer-icons/old/usercase_on.png";
import usercaseOff from "../../assets/drawer-icons/old/usercase_off.png";

class MobileBottomAppBarItem {
  __typename = "MobileBottomAppBarItem" as const;

  constructor(public title: string, public link: string, public icon: string, public iconActive?: string) {}
}

@Component({})
export default class MobileBottomAppBar extends Vue {
  activeBg = activeBg;
  private items = Object.freeze([
    new MobileBottomAppBarItem("Кейсы",  "/", caseOff, caseOn),
    new MobileBottomAppBarItem("User case",  "/usercase", usercaseOff, usercaseOn),
    new MobileBottomAppBarItem("Upgrade",  "/upgrade", upgradeOff, upgradeOn),
    new MobileBottomAppBarItem("Аккаунты",  "/accounts", accountsOff, accountsOn),
    new MobileBottomAppBarItem("Магазин",  "/shopskins", shopskinsOff, shopskinsOn),
  ]);
}
