
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { UserItemEntity } from "@/entities/user-item.entity";
import {
  ESteamItemRarity,
  SteamItemEntity,
} from "@/entities/steam-item.entity";
import Price from "@/components/base/Price.vue";
import { IS_MOBILE_SYMBOL } from "@/constants";
import FragmentPrice from "@/components/base/FragmentPrice.vue";

@Component({
  components: {
    Price,
    FragmentPrice,
  },
})
export default class Item extends Vue {
  @Inject(IS_MOBILE_SYMBOL) isMobile!: boolean;
  @Prop({ default: false }) basic!: boolean;
  @Prop({ default: false }) hideName!: boolean;
  @Prop({ default: false }) hidePrice!: boolean;
  @Prop({ default: false }) hideExterior!: boolean;
  @Prop({ default: 1 }) aspectRatio!: number;
  @Prop({ default: undefined }) selectable!: boolean | undefined;
  @Prop({
    default: () =>
      new SteamItemEntity({
        _id: String(Math.random()),
        name: "M4A4 | Tornado (Minimal Wear)",
        image:
          "https://community.cloudflare.steamstatic.com/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpou-6kejhz3Mzcfi9M7di5q4yCkP_gDLfQhGxUppB00ryT99ys3Qa1rUdrMD2mLdSdIQA-NAyG-1K5l-3r15696sjByHB9-n51bBhZfok",
        price: 100.11,
        rarity: ESteamItemRarity.PINKISH_PURPLE,
      }),
  })
  value!: UserItemEntity | SteamItemEntity;
}
