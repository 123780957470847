import Vue from "vue";
import Vuex from "vuex";
import { TDrawerState } from "@/constants";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    theme: `new`,
    drawerState: null,
    drawerRightState: null,
  } as {
    drawerState: TDrawerState;
    drawerRightState: TDrawerState;
    theme: `new` | `old`;
  },
  mutations: {
    setTheme(state, value) {
      state.theme = value;
    },
    setDrawerState(state, value: TDrawerState) {
      state.drawerState = value;
    },
    setDrawerRightState(state, value: TDrawerState) {
      state.drawerRightState = value;
    },
  },
  actions: {},
  modules: {},
});
