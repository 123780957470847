
import { Component, VModel, Vue } from "vue-property-decorator";
import DepositContent from "@/components/deposit/DepositContent.vue";
import BottomSheet from "@/components/base/BottomSheet.vue";

@Component({
  components: {
    DepositContent,
    BottomSheet,
  },
})
export default class DepositBottomSheet extends Vue {
  @VModel({ type: Boolean }) show!: boolean;
}
