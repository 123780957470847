
import { Component, Prop, Vue } from "vue-property-decorator";
import defaultBoxImage from "@/assets/default-box.png";
import BaseCard from "@/components/base/BaseCard.vue";
import Price from "@/components/base/Price.vue";
import { FreeBoxEntity } from "@/entities/free-box.entity";

@Component({
  components: {
    BaseCard,
    Price,
  },
})
export default class FreeBox extends Vue {
  @Prop() value!: FreeBoxEntity;
  defaultImage = defaultBoxImage;
}
