var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":"","id":"chat"}},[_c('div',{staticClass:"flex-column d-flex fit"},[_c('div',{staticClass:"flex-shrink-0"},[_c('heading',{attrs:{"icon":false,"title":"Чат"},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('span',{staticClass:"text-subtitle-1 subtitle font-weight-medium"},[_c('user-online-count',[_vm._v("online")])],1)]},proxy:true}])})],1),_c('v-fade-transition',[(_vm._messages)?_c('div',{staticClass:"flex-shrink-1 flex-grow-1 overflow-hidden chat-body"},[_c('virtual-list',{ref:"scroll",staticClass:"fit overflow-y-auto scroller",attrs:{"id":"chat-scroll","data-key":'_id',"data-sources":_vm.messages,"data-component":_vm.AppChatMessage,"estimate-size":_vm.minMessageHeight}})],1):_c('div',{staticClass:"overflow-hidden scroller flex-shrink-1 flex-grow-1 chat-body reverse",staticStyle:{"transform":"rotate(-180deg)"}},_vm._l((15),function(n){return _c('v-skeleton-loader',{key:n,staticClass:"mb-3 message-loading",staticStyle:{"transform":"rotate(-180deg)"},attrs:{"elevation":"0","type":[
              'list-item-avatar',
              'list-item-avatar-two-line',
              'list-item-avatar-three-line',
            ][n % 2 === 0 ? 0 : n % 3 === 0 ? 2 : 1]}})}),1)]),_c('div',{staticClass:"flex-shrink-0",staticStyle:{"cursor":"text"},on:{"click":() =>
          _vm.user
            ? _vm.$refs.input
              ? _vm.$refs.input.focus()
              : null
            : _vm.$router.push('#login')}},[_c('v-divider'),(_vm.chatBannedBefore)?[_c('vac',{ref:"vac",attrs:{"end-time":_vm.chatBannedBefore},on:{"finish":function($event){_vm.user.chatBannedBefore = 0}},scopedSlots:_vm._u([{key:"process",fn:function({ timeObj }){return [_c('div',{staticClass:"full-width text-center text-subtitle2 d-flex justify-center align-end",staticStyle:{"height":"42px"}},[_vm._v(" Блокировка: "),(timeObj.d !== '0')?[_vm._v(" "+_vm._s(timeObj.d)+" д. ")]:_vm._e(),_vm._v(" "+_vm._s(`${timeObj.h}:${timeObj.m}:${timeObj.s}`)+" ")],2)]}}],null,false,2646192354)})]:_c('v-text-field',{ref:"input",staticClass:"pt-3",attrs:{"dense":"","loading":_vm.sending,"readonly":_vm.sending || !_vm.user,"hide-details":"","disabled":_vm.disableChatInput,"placeholder":"Сообщение","full-width":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.send.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{staticClass:"pt-1 mt-n2 send-btn",attrs:{"icon":"","disabled":!_vm.text || _vm.sending || !_vm.user},on:{"click":_vm.send}},[_c('img',{directives:[{name:"svg-inline",rawName:"v-svg-inline"}],attrs:{"src":_vm.chatBtn}})])]},proxy:true}]),model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }