export class BaseBoxEntity {
  _id!: string;
  image?: string;
  name?: string;
  price!: number;

  constructor(opts) {
    Object.assign(this, opts);
  }
}
