
import { Component, Prop, Vue } from "vue-property-decorator";
import AnimatedNumber from "./AnimatedNumber.vue";
import { FragmentIdEnum } from "@/interfaces/fragment-box.interface";

@Component({
  components: {
    AnimatedNumber,
  },
})
export default class FragmentPrice extends Vue {
  @Prop({ default: undefined }) animated!: boolean | undefined;
  @Prop() value!: number;
  @Prop() fragmentId!: FragmentIdEnum;
  @Prop({ default: undefined }) round!: number;
  @Prop({ default: undefined }) right!: boolean;
  @Prop({ default: true }) icon!: boolean;
  @Prop() iconColor!: string;
  @Prop() iconClass!: string;
  @Prop({ type: Boolean, default: false }) hidePrice!: boolean;
}
