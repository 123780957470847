
import { Component, Prop, Vue } from "vue-property-decorator";
import defaultAccountImage from "@/assets/default-account.svg";
import BaseCard from "@/components/base/BaseCard.vue";
import { AccountBoxEntity } from "@/entities/account-box.entity";
import Price from "@/components/base/Price.vue";

@Component({
  components: {
    BaseCard,
    Price,
  },
})
export default class AccountBox extends Vue {
  @Prop() value!: AccountBoxEntity;
  defaultImage: string = defaultAccountImage;
}
