
import {
  Component,
  InjectReactive,
  Ref,
  Vue,
  Watch,
} from "vue-property-decorator";

import Heading from "@/components/base/Heading.vue";
import chatBtn from "@/assets/chat-btn.svg";
import { MessageEntity } from "@/entities/message.entity";
import gql from "graphql-tag";
import { ChatGiveawayFragment, ChatMessageFragment } from "@/graphql/fragments";
import ScrollFetchMore from "@/components/base/ScrollFetchMore.vue";
import { getElementScrollHeight, getElementScrollPosition } from "@/helpers";
import anime from "animejs";
import { USER_SYMBOL } from "@/constants";
import { AuthUserEntity, UserEntity } from "@/entities/user.entity";
import VueAwesomeCountdown from "vue-awesome-countdown";
import UserOnlineCount from "@/components/app/AppUserOnlineCount.vue";
import VirtualList from "vue-virtual-scroll-list";
import AppChatMessage from "@/components/app/AppChatMessage.vue";
import * as last from "lodash/last";
import { Howl, Howler } from "howler";
import chatMessageAudio from "@/assets/audio/message.mp3";
import { ChatGiveawayEntity } from "@/entities/chat-giveaway.entity";
import { useScroll } from "@/graphql/use-scroll";
import { useFindAll } from "@/graphql/use-find-all";

const CHAT_MESSAGE_AUDIO = new Howl({
  src: [chatMessageAudio],
  preload: true,
});

@Component({
  components: {
    Heading,
    VirtualList,
    UserOnlineCount,
    ScrollFetchMore,
  },
  apollo: {
    giveaway: {
      update(data) {
        return data.chatGiveaway
          ? new ChatGiveawayEntity(data.chatGiveaway)
          : null;
      },
      query: gql`
        query {
          chatGiveaway {
            ...ChatGiveaway
          }
        }
        ${ChatGiveawayFragment}
      `,
      fetchPolicy: "cache-and-network",
      subscribeToMore: [
        {
          updateQuery(previousResult, { subscriptionData }) {
            const key = Object.keys(previousResult)[0];
            previousResult[key] = Object.assign(
              previousResult[key] || {},
              subscriptionData.data.subscribeChatGiveawayUpdated
            );

            if (previousResult[key].active === false) {
              this.disableGiveaway(previousResult[key]);
            }

            return { [key]: previousResult[key] };
          },
          document: gql`
            subscription {
              subscribeChatGiveawayUpdated {
                ...ChatGiveaway
              }
            }
            ${ChatGiveawayFragment}
          `,
        },
      ],
    },
    _messages: {
      update(data) {
        if (data.findAllChatMessage && !this._messages) {
          this.unshiftMessages(data.findAllChatMessage);
        }

        return data.findAllChatMessage ? data.findAllChatMessage : null;
      },
      query: useFindAll("ChatMessage", ChatMessageFragment),
      variables: {
        options: {
          limit: 25,
          populate: "user",
          sort: "-_id",
        },
      },
      fetchPolicy: "no-cache",
      subscribeToMore: [
        {
          updateQuery(previousResult, { subscriptionData }) {
            const key = Object.keys(previousResult)[0];
            const item = subscriptionData.data.subscribeChatMessageAdded;

            this.pushMessage(item);

            return {
              [key]: [item, ...previousResult[key]],
            };
          },
          document: gql`
            subscription {
              subscribeChatMessageAdded {
                ...ChatMessage
              }
            }
            ${ChatMessageFragment}
          `,
        },
        {
          updateQuery(previousResult, { subscriptionData }) {
            const key = Object.keys(previousResult)[0];
            const item = subscriptionData.data.subscribeChatMessageUpdated;
            const itemToUpdate = previousResult[key].find(
              ({ _id }) => _id === item._id
            );
            const itemToUpdate1 = this.messages.find(
              ({ _id }) => _id === item._id
            );

            if (itemToUpdate) {
              itemToUpdate.text = item.text;
              itemToUpdate.deleted = item.deleted;
            }

            if (itemToUpdate1) {
              itemToUpdate1.text = item.text;
              itemToUpdate1.deleted = item.deleted;
            }

            return {
              [key]: [...previousResult[key]],
            };
          },
          document: gql`
            subscription {
              subscribeChatMessageUpdated {
                _id
                deleted
                text
              }
            }
          `,
        },
      ],
    },
  },
})
export default class Chat extends Vue {
  chatBtn = chatBtn;
  AppChatMessage = AppChatMessage;
  private sending = false;
  private text = "";
  private minMessageHeight = 93;
  private disableChatInput = false;
  private giveaway: ChatGiveawayEntity | null = null;
  private _messages: any[] | null = null;
  private messages: MessageEntity[] = [];
  private scrollAnimation: any | null = null;
  @InjectReactive(USER_SYMBOL) user!: AuthUserEntity | null;
  @Ref("vac") vac!: VueAwesomeCountdown | undefined;

  unshiftMessages(messages: any[]) {
    this.messages = [
      ...messages.reverse().map((v) => new MessageEntity(v)),
      ...this.messages,
    ];
  }

  pushMessage(message: any) {
    const msg = new MessageEntity(message);

    if (this.user && msg.mentions.includes(this.user.name)) {
      CHAT_MESSAGE_AUDIO.play();
    }
    this.messages.push(msg);
  }

  @Watch("chatBannedBefore")
  async chatBannedBeforeChanged(newValue, oldValue) {
    if (oldValue && this.vac) {
      await this.$nextTick();
      this.vac?.startCountdown(true);
    }
  }

  get chatBannedBefore(): number {
    return this.user
      ? this.user.chatBannedBefore || 0 > Date.now()
        ? this.user.chatBannedBefore || 0
        : 0
      : 0;
  }

  addText(text) {
    if (this.text && last(this.text) !== " ") {
      this.text += " ";
    }

    this.text += text + " ";
    this.$refs.input!["focus"]();
  }

  disableGiveaway(giveaway: ChatGiveawayEntity) {
    this.disableChatInput = true;

    setTimeout(() => {
      if (giveaway._id == this.giveaway!._id) {
        this.giveaway = null;
      }
      this.disableChatInput = false;
    }, 7000);
  }

  @Watch("messages.length")
  async messagesWatcher(newLength, oldLength) {
    await this.$nextTick();
    const scrollPositionFromBottom =
      getElementScrollHeight("#chat-scroll") -
      getElementScrollPosition("#chat-scroll");

    if (
      (oldLength && scrollPositionFromBottom <= this.minMessageHeight * 5) ||
      this.scrollAnimation
    ) {
      await this.scrollToBottom(200);
    }
  }

  mounted() {
    const unwatch = this.$watch("messages", (value) => {
      if (Array.isArray(value)) {
        this.scrollToBottom();
        unwatch();
      }
    });
  }

  async scrollToBottom(ms = 0, delay = 0) {
    await new Promise((resolve) => setTimeout(resolve, delay));
    if (this.$refs.scroll) {
      if (ms === 0 || !document.hasFocus()) {
        await (this.$refs.scroll as any).scrollToBottom();
      } else {
        if (this.scrollAnimation) {
          await this.scrollAnimation.finished;
        }

        this.scrollAnimation = anime({
          targets: "#chat-scroll",
          scrollTop: getElementScrollHeight("#chat-scroll"),
          duration: ms,
          easing: "linear",
          complete: () => {
            this.scrollAnimation = null;
          },
        });
      }
    }
  }

  async execChatCommand(text: string) {
    const [command, ...args] = text.replace("/", "").split(" ");
    this.text = "";

    if (command === "giveaway") {
      let argsStr = args.join(" ");
      let result = /^"(.+)" "(.+)" (\d+(\.\d+)?)$/i.exec(argsStr);

      if (result) {
        return this.createChatGiveaway(result[1], result[2], Number(result[3]));
      }
    }
  }

  async createChatGiveaway(question: string, answer: string, prize: number) {
    const mutation = {
      mutation: gql`
        mutation ($input: CreateChatGiveawayInput!) {
          createChatGiveaway(input: $input) {
            _id
          }
        }
      `,
      variables: {
        input: {
          question,
          answer,
          prize,
        },
      },
    };

    return this.$apollo.mutate(mutation).catch((e) => {
      this.$notify({
        text: e.message,
        type: "error",
      });
    });
  }

  async send() {
    if (this.sending || !this.text) {
      return;
    }

    if (this.text[0] === "/") {
      return this.execChatCommand(this.text);
    }

    this.sending = true;
    const mutationName = this.giveaway
      ? "createGiveawayChatMessage"
      : "createChatMessage";
    const mutation = {
      mutation: gql`
        mutation ($text: String!) {
          ${mutationName}(text: $text) {
            _id
          }
        }
      `,
      variables: {
        text: this.text,
      },
    };

    try {
      await this.$apollo.mutate(mutation);
    } catch (e) {
      if (e.message.indexOf("ThrottlerException") !== -1) {
        const timeMatcher = e.message.match(/\s\d+$/);
        const time =
          new Date(
            timeMatcher ? Number(timeMatcher[0]) + 500 : Date.now() + 3000
          ).getTime() - Date.now();
        await new Promise((resolve) => setTimeout(resolve, time));
        await this.$apollo.mutate(mutation).catch(console.error);
      } else if (e.message.indexOf("AccessDeniedException") !== -1) {
        this.$notify({ type: "info", text: `Вы заблокированны в этом чате.` });
      }
    }

    this.text = "";
    this.sending = false;
    (this.$refs.input as any).focus();
  }
}
