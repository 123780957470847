
    import {Component, Inject, Prop, Vue, Watch} from "vue-property-decorator";
import { Howl, Howler } from "howler";
    import {IS_MOBILE_SYMBOL} from "@/constants";

const VOLUME_KEY = "APP:VOLUME";

@Component({})
export default class AppVolumeControl extends Vue {
    @Inject(IS_MOBILE_SYMBOL) isMobile!: boolean;
  @Prop() iconColor!: any;
  @Prop() iconStyle!: any;
  @Prop({ default: true }) vertical!: boolean;
  volume: number = this.isMobile ? 0 : 0.5;

  get volumePercentage() {
    return Number((this.volume * 100).toFixed(2));
  }

  set volumePercentage(value) {
    this.volume = Number((value / 100).toFixed(2));
  }

  created() {
    if (this.$localStorage.hasKey(VOLUME_KEY)) {
      this.volume = this.$localStorage.get(VOLUME_KEY);
    }else{
        this.volumeWatcher();
    }
  }

  @Watch("volume")
  volumeWatcher() {
    this.$localStorage.set(VOLUME_KEY, this.volume);
    Howler.volume(this.volume);
  }
}
