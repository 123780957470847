
import {
  Component,
  Inject,
  InjectReactive,
  Vue,
  Watch,
} from "vue-property-decorator";
import Chat from "@/components/app/AppChat.vue";
import LiveDrop from "@/components/live-drop/LiveDrop.vue";
import { IS_MOBILE_SYMBOL, TDrawerState, USER_SYMBOL } from "@/constants";
import { AuthUserEntity } from "@/entities/user.entity";

@Component({
  components: {
    Chat,
    LiveDrop,
  },
})
export default class DrawerRight extends Vue {
  @InjectReactive(USER_SYMBOL) user!: AuthUserEntity | null;
  @Inject(IS_MOBILE_SYMBOL) isMobile!: boolean;
  private mini = false;
  private show = true;
  private chatWidth = 330;
  private liveDropWidth = 200;

  get value() {
    return this.$store.state.drawerRightState;
  }

  set value(value) {
    this.$store.commit("setDrawerRightState", value);
  }

  // Регулирует, должен ли быть чат
  get chat() {
    return this.$store.state.theme === "new";
  }

  get width(): number {
    let width = 0;

    if (this.chat) {
      width += this.chatWidth;
    }

    if (!this.mini) {
      width += this.liveDropWidth;
    }

    return width;
  }

  @Watch("chat")
  chatWatcher(showChat) {
    if (!showChat) {
      this.mini = false;
    }
  }

  @Watch("show", { immediate: true })
  @Watch("mini", { immediate: true })
  updateValue() {
    let newValue: TDrawerState = "show";

    if (!this.show) {
      newValue = "hide";
    } else if (this.mini) {
      newValue = "mini";
    }

    this.value = newValue;
  }

  created() {
    this.show = !this.isMobile;
    this.mini = !this.chat ? false : this.$vuetify.breakpoint.mdAndDown;
  }
}
