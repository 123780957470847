
import { Component, Inject, Mixins, Vue } from "vue-property-decorator";
import BoxList from "@/components/boxes/BoxList.vue";
import { BoxEntity } from "@/entities/box.entity";
import gql from "graphql-tag";
import {
  AccountBoxFragment,
  BoxFragment,
  FragmentBoxFragment,
  FreeBoxFragment,
  GunBoxFragment,
} from "@/graphql/fragments";
import { FreeBoxEntity } from "@/entities/free-box.entity";
import TopAppBar from "@/components/base/TopAppBar.vue";
import FreeBoxList from "@/components/boxes/FreeBoxList.vue";
import FreeBoxSlideList from "@/components/boxes/FreeBoxSlideList.vue";
import { IS_MOBILE_SYMBOL } from "@/constants";
import BoxSlideList from "@/components/boxes/BoxSlideList.vue";
import AccountBoxSlideList from "@/components/accounts/AccountBoxSlideList.vue";
import { AccountBoxEntity } from "@/entities/account-box.entity";
import AccountBoxList from "@/components/accounts/AccountBoxList.vue";
import LiveDropSlideList from "@/components/live-drop/LiveDropSlideList.vue";
import SnowBoxes from "@/components/boxes/SnowBoxes.vue";
import { useFindAll } from "@/graphql/use-find-all";
import GunBoxList from "@/components/boxes/GunBoxList.vue";
import GunBoxSlideList from "@/components/boxes/GunBoxSlideList.vue";
import { GunBoxEntity } from "@/entities/gun-box.entity";
import { IFragmentBox } from "@/interfaces/fragment-box.interface";
import FragmentBox from "@/components/boxes/FragmentBox.vue";
import FragmentBoxList from "@/components/boxes/FragmentBoxList.vue";
import FragmentBoxSlideList from "@/components/boxes/FragmentBoxSlideList.vue";
import FindAllGunBoxMixin from "@/mixins/find-all-gun-box.mixin";

@Component({
  components: {
    FragmentBoxSlideList,
    FragmentBoxList,
    FragmentBox,
    SnowBoxes,
    LiveDropSlideList,
    BoxList,
    AccountBoxList,
    FreeBoxSlideList,
    TopAppBar,
    AccountBoxSlideList,
    BoxSlideList,
    FreeBoxList,
    GunBoxList,
    GunBoxSlideList,
  },
  apollo: {
    accountBoxes: {
      update(data) {
        return data.findAllAccountBox
          ? data.findAllAccountBox.map((item) => new AccountBoxEntity(item))
          : null;
      },
      fetchPolicy: "cache-and-network",
      query: useFindAll("AccountBox", AccountBoxFragment),
      variables: {
        options: {
          sort: "-accountIds price",
        },
      },
    },
    boxes: {
      update(data) {
        return data.findAllBox
          ? data.findAllBox.map((box) => new BoxEntity(box))
          : null;
      },
      fetchPolicy: "cache-and-network",
      query: useFindAll("Box", BoxFragment),
      variables() {
        return {
          options: {
            sort: "price",
          },
        };
      },
    },
    fragmentBoxes: {
      update(data) {
        return data.findAllFragmentBox ? data.findAllFragmentBox : null;
      },
      query: useFindAll("FragmentBox", FragmentBoxFragment),
    },
    freeBoxes: {
      update(data) {
        return data.findAllFreeBox
          ? data.findAllFreeBox.map((box) => new FreeBoxEntity(box))
          : null;
      },
      fetchPolicy: "cache-and-network",
      query: useFindAll("FreeBox", FreeBoxFragment),
      variables() {
        return {
          options: {
            sort: "price",
          },
        };
      },
    },
  },
})
export default class Home extends Mixins(FindAllGunBoxMixin) {
  @Inject(IS_MOBILE_SYMBOL) isMobile!: boolean;
  private boxes: BoxEntity[] | null = null;
  private fragmentBoxes: IFragmentBox[] | null = null;
  private freeBoxes: FreeBoxEntity[] | null = null;
  private accountBoxes: AccountBoxEntity[] | null = null;
}
