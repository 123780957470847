
import { Component, Vue } from "vue-property-decorator";
import GradientBtn from "@/components/base/GradientBtn.vue";
import Item from "@/components/items/Item.vue";
import ScrollFetchMore from "@/components/base/ScrollFetchMore.vue";
import {
  FromDocumentTypeEnum,
  UserItemEntity,
} from "@/entities/user-item.entity";
import gql from "graphql-tag";
import { BattleFragment, UserItemWithUserFragment } from "@/graphql/fragments";
import { useFindAll } from "@/graphql/use-find-all";

const PUSH_DELAY_MAP = new Map<FromDocumentTypeEnum, number>([
  [FromDocumentTypeEnum.BOX, 10000],
  [FromDocumentTypeEnum.GUN_BOX, 10000],
  [FromDocumentTypeEnum.FREE_BOX, 10000],
  [FromDocumentTypeEnum.SNOW_BOX, 10000],
  [FromDocumentTypeEnum.FRAGMENT_BOX, 10000],
  [FromDocumentTypeEnum.UPGRADE, 5000],
  [FromDocumentTypeEnum.USER_BOX, 10000],
  [FromDocumentTypeEnum.USER_ITEM, 0],
]);

@Component({
  apollo: {
    items: {
      update(data) {
        return data.findAllUserItem
          ? data.findAllUserItem.map((item, index) => {
              return new UserItemEntity(item);
            })
          : null;
      },
      query: useFindAll("UserItem", UserItemWithUserFragment),
      variables() {
        return {
          options: {
            sort: "-receivedAt",
            populate: "user fromDocument",
            limit: 10,
          },
          filter: {
            userId: { exists: true },
            receivedAt: { gte: new Date().getTime() - 24 * 60 * 60 * 1000 },
          },
        };
      },
      subscribeToMore: {
        updateQuery(previousResult, { subscriptionData }) {
          const key = Object.keys(previousResult)[0];
          const item = new UserItemEntity(
            subscriptionData.data.subscribeUserItemAdded
          );

          if (item.fromDocumentType === FromDocumentTypeEnum.BATTLE) {
            const battle = this.$apollo.provider.defaultClient.readFragment({
              id: "Battle:" + this.$route.params.id,
              fragmentName: "Battle",
              fragment: BattleFragment,
            });
            this.addItemToQueue(
              item,
              battle
                ? (PUSH_DELAY_MAP.get(FromDocumentTypeEnum.GUN_BOX) || 0) *
                    battle.gunBoxIds.length
                : 0
            );
          } else {
            this.addItemToQueue(
              item,
              PUSH_DELAY_MAP.get(item.fromDocumentType) || 0
            );
          }

          return {
            [key]: [item, ...previousResult[key]],
          };
        },
        document: gql`
          subscription {
            subscribeUserItemAdded {
              ...UserItemWithUser
            }
          }
          ${UserItemWithUserFragment}
        `,
      },
    },
  },
  components: {
    GradientBtn,
    ScrollFetchMore,
    Item,
  },
})
export default class LiveDrop extends Vue {
  private items: UserItemEntity[] | null = null;
  private itemsQueue: UserItemEntity["_id"][] = [];

  get showedItems() {
    return this.items?.filter(
      (item) => this.itemsQueue.indexOf(item._id) === -1
    );
  }

  removeItemFromQueue(item: UserItemEntity) {
    this.itemsQueue.splice(this.itemsQueue.indexOf(item._id), 1);
  }

  async addItemToQueue(item: UserItemEntity, time: number) {
    this.itemsQueue.push(item._id);
    await this.sleep(time);

    if (this.itemsQueue[0] !== item._id) {
      const unWatch = this.$watch(`itemsQueue`, (value) => {
        if (value[0] === item._id) {
          this.removeItemFromQueue(item);
          unWatch();
        }
      });
    } else {
      this.removeItemFromQueue(item);
    }
  }

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
