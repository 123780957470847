
import { Component, InjectReactive, Prop, Vue } from "vue-property-decorator";
import { USER_SYMBOL } from "@/constants";
import { AuthUserEntity } from "@/entities/user.entity";
import Balance from "@/components/base/Balance.vue";

@Component({
  components: {
    Balance,
  },
})
export default class MobileTopAppBar extends Vue {
  @InjectReactive(USER_SYMBOL) user!: AuthUserEntity | null;
  @Prop({ default: false }) balance!: boolean;
  @Prop({ default: false, type: Boolean }) bonusBtn!: boolean;
  @Prop() title!: string;
}
