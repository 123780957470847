export class ChatGiveawayEntity {
    _id!: string;
    answer?: string;
    question!: string;
    prize!: number;
    active!: boolean;

    constructor(input: any) {
        Object.assign(this, input)
    }
}
