
import { Component, Prop, Vue } from "vue-property-decorator";
import AccountBox from "@/components/accounts/AccountBox.vue";
import BaseList from "@/components/base/BaseList.vue";
import { AccountBoxEntity } from "@/entities/account-box.entity";

@Component({
  components: {
    AccountBox,
    BaseList,
  },
})
export default class AccountBoxList extends Vue {
  @Prop() value!: AccountBoxEntity[];
  @Prop( {default: 20}) loadingCount!: number;
}
